*{
    font-size: 18px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin:0;

}
h6{
    font-weight: 400;
}
a {
    text-decoration: none;
}

a:active {
    color: #0000FF;
  }

html,body {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}


.bg-dark{
    background-color: #FFFFFF!important;
}

.navbar-dark .navbar-toggler {
        color: rgba(0,0,0,.55)!important;
        border-color: rgba(0,0,0,.1)!important;

 }

.navbar-dark .navbar-nav .nav-link:hover{
    color: #A41505;
}
.navbar-dark .navbar-nav .nav-link:active {
    color: #A41505;
}

.navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
}

.me-auto {
    margin-right: unset !important;
}
.logo-img {
    width: 152px;
    height: 147px;  
}

.sliderText{
    color: var(--unnamed-color-a41505);
    display: flex;
    justify-content: flex-end;
    margin-right: -5vw;
    font-weight: 500;
    font-size: 3vw;
    font-family: Montserrat;
    color: #A41505;
    opacity: 1;
}
.whatsappIcon{
    background: transparent;
    border: none;
    color: white;
}
.imagefloat{
    position: absolute;
     right: -20%;
     top: 61%;
     z-index: -1;
     opacity:0.5;
}
.parent-div-va{
    position: relative;
}
.menuicon svg {
    color: #ED7B0B;
}

.top-header-section {
    position: relative;
    height: 56px;
    background: #A41505;
}
.top-header{
    padding-top: 1em;
    font: normal normal 400 16px/25px Poppins;
    color: #ffffff; 
    display: flex;   
    justify-content: space-between;
}
.top-header-div{
    display: inline-block;
}

.top-header a{
    color: #FFFFFF;
    text-decoration: none;
}
.top-header .right-align {
    text-align: right;
}
.navbar-expand-md{
    height: 160px;
}

.MuiPaper-root a{
    color:#A41505;
    text-decoration: none;
    letter-spacing: 0!important;
}
.css-10hburv-MuiTypography-root{
    font-weight: 700!important;
    font-size: 1.2rem!important;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
    min-width: 230px;
}
/* thankyou page */
.css-14b29qc{
    justify-content: center!important;
}
.btn-primary {
    font: normal normal 500 1.2rem/1.4rem Poppins;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    color: #FFFFFF;
    background-color: #e57f19;
    text-decoration: none;
}
.btn-primary-light{
    font: normal normal 500 1.2rem/1.4rem Poppins;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    color: #a41505;
    background-color: #f0b375;
    text-decoration: none;
}

#donation .btn-primary {
    background-color: #e57f19;
}

.btn-primary:hover {
    background-color: #a41505;
}
.btn-primary-light:hover{
    text-decoration: underline;
    color: #A41505;
}

.btn-primary:focus {
    background-color: #a41505;
}
.btn-primary-light:focus{
    text-decoration: underline;
    color: #A41505;
}

#donation .btn-primary:hover {
    background-color: #a41505;
}

h2 {
    font-weight: 500 ;
    color: #A41505;
    font-family: 'Montserrat', sans-serif;
}

h2::after {
    background-image: url(../images/group.png);
    background-repeat: no-repeat;
    display: block;
    width: -webkit-fill-available;
    height: 20px;
    content: "";
}

.termsandconditions h2::after{
    background-image: unset;
    background-repeat: unset;
}

.termsandconditions section:not(:first-child){
    padding: 20px 0;
}
.termsandconditions a{
    text-decoration: none!important;
}

#about h2::after{
    background-position: top left;
}

.about-container{
    padding: 5vw 0;
    background: url('../images/Group-9.png') 102% 50% no-repeat;
}

#gallery h2::after {
    background-position: top center;    
}

#gallery , .bg-accent{
    background-color: rgba(241, 130, 32, 0.05) ;    
}

#gallery::after {
    background-image: url(../images/design.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    display: block;
    width: -webkit-fill-available;
    height: 55px;
    content: "";
}

.support-image {
    display: flex;
    top:2
}

#mediaCoverage h2::after , #donation h2::after{
    background-position: top center;    
}

.card {
    padding: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 40px 10px;
    background: rgba(241, 130, 32, 0.05);
    transition: all 0.3s ease-in-out;
    text-align: center;
    border: none;
}

.card:hover {
        box-shadow: 0px 6px 15px #A31D1229;
        background: #ffffff;
}

.more-button {
    font-weight: 500;
    display: inline-block;
    font-size: 18px;
    font-family: Poppins;
    padding: 12px 28px 12px 28px;
    color: #A41505bb;
    text-decoration: none;
}
.more-button:hover {
    color: #A41505;
}

#donation {
    background:linear-gradient(255deg, #F18220B2 0%, #A415055A 100%) padding-box;
    opacity: 80%;
    text-align: center;    
}
.donate{
    background: url('../images/image12.svg') 0% 100% no-repeat;
}

.large-text {
    font: normal normal 500 45px/54px Montserrat;
    color: #FFFFFF; 
}
.large-text span {
    font: normal normal 500 45px/54px Montserrat;
    color: #A41505;
}

.helper-text{
    font: normal normal normal 18px/24px Poppins;
    color: #FFFFFF;
    padding-bottom: 1rem;
    opacity: 0.8;
}

#contact::after {
    background-image: url(../images/design.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    display: block;
    width: -webkit-fill-available;
    height: 55px;
    content: "";
}

#contact .address {
    font-weight: 300!important;
}

#contact h6 a{
    color:#000000;
    text-decoration: none;
}

#contact .we-serve ul {
    padding-left: unset;
}

#contact .services {
    list-style-image: url(../images/sprint.svg);
}

#contact .services li.service-item:not(:last-child) { 
    margin-bottom: 18px;  
 } 

#contact .services li a{
    color: #A41505;

}
#contact .registration-no {
    font-weight: 400;
}

#contact span {
    color: #A41505;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
}

.pt-3px {
    padding-top: 3px;
}

.know-us {
    padding-left: 10vw;
}

#footer ul li a {
    font-size: 16px;
}

.footer-contact h6, .footer-contact h6 a {
    font-size: 16px;
    font-weight: 400;
}

.copyright p {
    margin: 0;
    font-size: 12px;
}
#footer p {
    margin-top: 10px;
}

.footerTop p {
 font-size: 14px;
}

#footer {
    background: url('../images/footer.png') 48% 100% no-repeat, linear-gradient(106deg, #A41505CC 0%, #A42405D8 13%, #CC5927E5 44%, #CC5F15E5 78%, #F18220CC 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

#footer section {    
    background-position: center;
    padding-top: 50px;
    padding-bottom: 10px;
}

#footer ul {
    padding-left: unset;
    list-style: none;
}

#footer ul li {
    line-height: 30px;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}

#footer h5 {
    font: normal normal 500 20px/30px Poppins;
    letter-spacing: 0px;
    opacity: 1;
    text-align: left;
    padding-top: 100px;
    padding-bottom: 10px;
    color: #ffffff;
    text-transform: uppercase;

}

#footer img {
    margin: auto;
    display: block;
}

.socialIcons{
    display: flex;
    list-style-type: none;
}

.socialIcons li a{
    color: #FFFFFF;
    text-decoration: none;
}

#footer h6 a {
    text-decoration: none;
    text-align: left;
    font: normal normal 500 /30px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.copyright{
    font: normal normal 400 20px/30px Poppins;
    letter-spacing: 0.6px;
    background: #A41505 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

#donation-breadcrumb{
    height:150px;
    position: relative;
    background: transparent linear-gradient(116deg, #F18220 0%, #F18220 26%, #F18220C1 30%, #F1822087 35%, #F1822057 39%, #F1822031 44%, #F1822016 47%, #F1822006 51%, #F1822000 53%, #F1822004 56%, #F1822011 60%, #F1822026 63%, #F1822044 66%, #F182206B 70%, #F182209A 73%, #F18220D0 77%, #F18220 79%, #F18220 100%) 0% 0% no-repeat padding-box;
    opacity: 0.65;
    text-align: center;
}
.breadcrumb{
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);
  }


.breadcrumb-item a {
    font: normal normal 500 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #F18220;
}
.breadcrumb-heading{
    font: normal normal 500 40px/49px Montserrat;
    color: #A41505;
    padding-top: 56px;
}
.bank-details-container{
    padding: 35px;
    margin-top: 100px;
    background: #FAEEE9 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #FAEEE9;
    border-radius: 10px;
}
.section-heading{
    text-align: center;
    padding-bottom: 30px;
    font: normal normal 500 28px/34px Montserrat;
    letter-spacing: 0px;
    color: #A41505;
    opacity: 0.8;
}
.bank-details-content{
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    padding: 3vw 10vw;
}
.donate-now-button-container{
    position: relative;
    height: 200px;
}
.donate-button{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.MuiDialog-paper h2::after{
    display: none;
}
#responsive-dialog-title {
    text-align: center;
    font: normal normal 500 50px/70px Montserrat;
    letter-spacing: 0px;
    color: #A41505;
}
.css-tlc64q-MuiPaper-root-MuiDialog-paper{
    max-width: 870px!important;
    padding: 50px 10px;
}
.css-qfso29-MuiTypography-root-MuiDialogContentText-root a{
    color:#A41505;
    font-weight: bold;
}
.css-hlj6pa-MuiDialogActions-root {
    justify-content: center!important;
}

/* Header */

.header-bottom {
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

/* END: Header */

/* Navbar */

.navbar ul {
    margin-top: 1rem;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li:first-child {
    padding-left: 0;
}

.navbar ul li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
 }

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-image{
    flex-basis: 30%;
}
.flex-content{
    flex-basis: 65%;
}
.about-image{
    min-height: 320px;
    object-fit: cover;
}
.current-page{
    border-bottom: 1px solid #A41505;
}

/* Donation Form */
.css-bhp9pd-MuiPaper-root-MuiCard-root{
    box-shadow: unset;
}
.text-area{
    border: 1px solid #A41505!important;
    background-color: white;
    border-radius: 10px;
}
.input-field{
    padding: 0 0 0 24px!important;
}
.MuiGrid-root, .MuiCardContent-root{
    background-color: #FAEEE9;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .MuiAutocomplete-inputRoot{
    border: 1px solid #A41505!important;
    background-color: white;
    border-radius: 5px;
}
.MuiTextField-root{
    background-color: white;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root{
    box-shadow: unset!important;
}
#donation-form form{
    padding-bottom: 10em;
}
#donation-form::after{
        background-image: url(../images/design.png);
        background-position: bottom center;
        background-repeat: no-repeat;
        display: block;
        width: -webkit-fill-available;
        height: 55px;
        content: "";
}
.danger{
    color: #A41505;
}

.btn-third {
    font: normal normal 500 20px/30px Poppins;
    display: inline-block;
    padding: 12px 28px 12px 28px;
    font-size: 20px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #ED7B0B;
    
    
}

.btn-third:hover {
    color: #FFFFFF;
    background-color: #A41505;
    
}
.button-center {
    text-align: center;
}
.centertext {
    text-align: center;
}

#mediaCoverage .title {
    font: normal normal 500 22px/42px Poppins;
    margin-bottom: 15px;
    margin-top: 20px;
    text-align: center;
    color: #A41505;
}

#mediaCoverage .description {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 0;
    text-align: center;
}
.mediaCoverage .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 40px 10px;
    background: #fff;
    transition: all 0.3s ease-in-out;
    text-align: center;
}
/* #donation {
    padding: 50px;} */
    
.donate a {
    text-decoration: none;
    
}

.donate .primary {
    text-align: center;
    font: normal normal 500 50px/60px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF; 
    color: #ffffff;
}
.donate .primary span {
    color: var(--unnamed-color-a41505);
    text-align: center;
    font: normal normal 500 50px/60px Montserrat;
    letter-spacing: 0px;
    color: #A41505;
    font-weight: 650;
}
.primary {
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 1px;
    margin: -10px 0 30px 0;
    
}
.subPara{
    text-align: center;
    font: normal normal normal 20px/30px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.8;
}

.btn-second {
    font: normal normal 500 22px/28px Poppins;
    display: inline-block;
    padding: 12px 28px 12px 28px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #ED7B0B;
}

.btn-second:hover {
    color: #FFFFFF;
    background-color: #A41505;
    
}
#mediaCoverage .box {
    padding: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 40px 10px;
    background: rgba(241, 130, 32, 0.05) ;

    transition: all 0.3s ease-in-out;
    text-align: center;
}

#mediaCoverage .box:hover {
    box-shadow: 0px 6px 15px #A31D1229;
    background: #ffffff;
}

#mediaCoverage .icon {
    margin: 0 auto 15px auto;
    padding-top: 12px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}

#mediaCoverage .icon i {
    font-size: 36px;
    line-height: 1;
}

.policy h2 {
    margin-top: 2rem;
    margin-bottom: unset;
}

.policy h2::after {
    all: unset;
}

h2.type-3::after {
    left: unset;
    margin-top: 10px;
    margin-left: unset;
}


.rightText {
    text-align: right;
}

.offcanvas-title{
    margin: auto!important;
    font-size: large;
}
.offcanvas{
    width: 50%;
    background-color :#fcd9b6
}

.nav-link {
    color: #A41505!important;
    text-align: left;
    margin:0.25em .5em;
    padding: 0;
    font: normal normal 500 20px/24px Montserrat;
}
.sliderText{        
    margin-top: -28vw;    
 }
/* Smaller than standard 960 (devices and browsers) */
                                                                                    
/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (max-width: 767px) {
    *{
        font-size: 14px;
        text-align: center;
    }

    .logo-img {
        width:6rem;
        height: 6rem;
    }
    .sliderText{        
        margin-top: -23vw;    
     }
    .navbar-expand-md{
        height: 73px;
     }
    .btn-primary {
        font: normal normal 500 1rem/1.2rem Poppins;
        padding: 0.3rem 1rem;
        border-radius: 0.3rem;
    }
    .flex {
        flex-direction: column;
    }
    .flex-image{
        max-height: 320px;
    }
    .about-image{
        max-height: 320px;
    }
    /* .flex-content{
        width: 100%;
    } */
    #mediaCoverage .title {
        font: normal normal 500 19px/22px Poppins;
    }
    
    #mediaCoverage .description {
        line-height: 22px;
    }
    .more-button {
        font-size: 14px;
        padding: 8px 20px;
    }

    .large-text {
        font: normal normal 500 20px/26px Montserrat;
    }
    .large-text span {
        font: normal normal 500 20px/26px Montserrat;
    }
    .helper-text{
        font: normal normal normal 12px/16px Poppins;
        color: #FFFFFF;
    }

    #contact .services {
    list-style-type: none;
    list-style-image: none;
    }

    #contact .services li.service-item:not(:last-child) { 
        margin-bottom: 12px;  
    }
    h2::after {
        width: 110px;
        margin: auto;
    }

    #contact h2::after{
        background-position: top center;    
        width: 110px;
        margin: auto;
    }
    .donate{
        background:none;
    }

    .know-us {
        padding-left: 0;
    }
    
    #contact span {
        font-size: 16px;
        line-height: 20px;
    }
    #contact .registration-no {
        line-height: 10px;
    }

    #footer ul li a, .footer-contact h6, .footer-contact h6 a {
        font-size: 12px;
    }

    #footer h5 {
        font: normal normal 500 18px/24px Poppins;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;    
    }

    .socialIcons{
        justify-content: center;
    }

    .copyright p {
        font-size: 8px;
        line-height: 10px;
    }
    .footerTop p {
        font-size: 10px;
    }
    #responsive-dialog-title {
        font: normal normal 500 30px/40px Montserrat;
    }

}